import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Profile() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  // Safely access user details
  if (isLoading) {
    return <div>Loading profile...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>Please log in to view this content.</div>;
  }

  // Access custom user metadata
  const userMetadata = user['http://localhost:3000/user_metadata'];

  return (
    <div className="container mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <h3>Welcome {user.name}</h3>
        <DropdownMenu />
      </div>
      
      <p><strong>Email Address:</strong> {user.email}</p>
      <p><strong>Your Total Contributed Capital:</strong> ${userMetadata.contributed_capital}</p>
            
    </div>
  );
}

export default Profile;
