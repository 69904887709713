import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Dropdown } from 'react-bootstrap';

function DropdownMenu() {
  const { logout, user } = useAuth0();

  if (!user) {
        return null;  // or some other placeholder or loading state
    }

  return (    
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic" style={{ backgroundColor: 'transparent', borderColor: 'gray', color: 'black' }}>
          {user.name}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="/profile">Profile</Dropdown.Item>
          <Dropdown.Item href="/letters">Letters</Dropdown.Item>
          <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
          <Dropdown.Item href="/portfolio">Portfolio</Dropdown.Item>
          <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>    
  );
}

export default DropdownMenu;

