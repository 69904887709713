import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Letters() {  
  const { isAuthenticated, isLoading, user } = useAuth0();


  // Safely access user details
  if (isLoading) {
    return <div>Loading profile...</div>;
  }

  if (!isAuthenticated || !user) {
    return <div>Please log in to view this content.</div>;
  }

  const letters = [
    { title: "Fiscal Year 2023 Annual Report", url: "/letters/FY2023-Update.pdf" },
    { title: "Q2 2023 Update", url: "/letters/2Q23-Update.pdf" },
    { title: "Q1 2023 Update", url: "/letters/1Q23-Update.pdf" },
    { title: "Q4 2022 Update", url: "/letters/4Q22-Update.pdf" },
    { title: "Q3 2022 Update", url: "/letters/3Q22-Update.pdf" },
    { title: "Q2 2022 Update", url: "/letters/2Q22-Update.pdf" },
    { title: "Q1 2022 Update", url: "/letters/1Q22-Update.pdf" },
    { title: "Q4 2021 & Q1 2022 Update", url: "/letters/4Q21-1Q22-Update.pdf" },
    { title: "Q2 & Q3 2021 Update", url: "/letters/2Q21-3Q21-Update.pdf" },
    { title: "Q1 2021 Update", url: "/letters/1Q21-Update.pdf" },
    { title: "Fiscal Year 2020 Annual Report", url: "/letters/FY2020-Update.pdf" },
    { title: "Q3 2020 Update", url: "/letters/3Q20-Update.pdf" },
    { title: "Q2 2020 Update", url: "/letters/2Q20-Update.pdf" },
    { title: "Fiscal Year 2019 Annual Report", url: "/letters/FY19-Annual-Report.pdf" },
  ];

  return (
    <div className="container mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <h3>Ragnar Capital Partners Updates and Letters</h3>
        <DropdownMenu />
      </div>

      <ul className="list-unstyled">
        {letters.map(letter => (
          <li key={letter.title}>
            <a href={letter.url} target="_blank" rel="noopener noreferrer">
              {letter.title}
            </a>
          </li>
        ))}
      </ul>          
    </div>
  );
}

export default Letters;
