import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Portfolio() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the portfolio.</div>;
    }

    const holdings = [
        { equities: "AST SpaceMobile", ticker: "ASTS", dividendYield: "--", costBasis: "$5,048", reinvestedDividends: "--", cashDividends: "--", marketValue: "$65,340", portfolioPercent: "15.2%", totalgainloss: "1,194%" },
        { equities: "Comtech Telecommunications Corp", ticker: "CMTL", dividendYield: "--", costBasis: "$39,997", reinvestedDividends: "--", cashDividends: "--", marketValue: "$48,980", portfolioPercent: "11.4%", totalgainloss: "23%" },
        { equities: "SoFi Technologies", ticker: "SOFI", dividendYield: "--", costBasis: "$45,003", reinvestedDividends: "--", cashDividends: "--", marketValue: "$43,498", portfolioPercent: "10.1%", totalgainloss: "(3%)" },        
        { equities: "PubMatic", ticker: "PUBM", dividendYield: "--", costBasis: "$44,992", reinvestedDividends: "--", cashDividends: "--", marketValue: "$35,490", portfolioPercent: "8.3%", totalgainloss: "(21%)" },        
        { equities: "BlackSky Technology", ticker: "BKSY", dividendYield: "--", costBasis: "$45,002", reinvestedDividends: "--", cashDividends: "--", marketValue: "$35,350", portfolioPercent: "8.2%", totalgainloss: "(21%)" },
        { equities: "Pinterest", ticker: "PINS", dividendYield: "--", costBasis: "$12,452", reinvestedDividends: "--", cashDividends: "--", marketValue: "$24,030", portfolioPercent: "5.6%", totalgainloss: "93%" },
        { equities: "AGNC Investment Corp", ticker: "AGNC", dividendYield: "13.5%", costBasis: "$15,103", reinvestedDividends: "$8,121", cashDividends: "$360", marketValue: "$22,164", portfolioPercent: "5.2%", totalgainloss: "49%" },
        { equities: "Magnite", ticker: "MGNI", dividendYield: "--", costBasis: "$29,374", reinvestedDividends: "--", cashDividends: "--", marketValue: "$20,685", portfolioPercent: "4.8%", totalgainloss: "(30%)" },
        { equities: "Equities Total", ticker: "--", dividendYield: "--", costBasis: "$237,331", reinvestedDividends: "$8,121", cashDividends: "$360", marketValue: "$295,537", portfolioPercent: "68.9%", totalgainloss: "25%" },
        { equities: "BlackRock Innovation & Growth Trust", ticker: "BIGZ", dividendYield: "8.6%", costBasis: "$15,489", reinvestedDividends: "$2,942", cashDividends: "--", marketValue: "$11,330", portfolioPercent: "2.6%", totalgainloss: "(27%)" },
        { equities: "Cash", ticker: "--", dividendYield: "--", costBasis: "--", reinvestedDividends: "--", cashDividends: "--", marketValue: "$122,207", portfolioPercent: "28.5%", totalgainloss: "--" },
        { equities: "Portfolio Value", ticker: "--", dividendYield: "1.8%", costBasis: "$332,000", reinvestedDividends: "--", cashDividends: "--", marketValue: "$429,075", portfolioPercent: "100.0%", totalgainloss: "29%" }
    ];


    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Portfolio Holdings (as of 8/30/24)</h3>
                <DropdownMenu />  
            </div>    
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Equities</th>
                        <th>Ticker</th>
                        <th>Dividend Yield</th>
                        <th>Cost Basis</th>
                        <th>Market Value</th>
                        <th>% of Portfolio</th>
                        <th>Total % gain/loss</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.map((holding, index) => (
                        <tr key={index}>
                            <td>{holding.equities}</td>
                            <td>{holding.ticker}</td>
                            <td>{holding.dividendYield}</td>
                            <td>{holding.costBasis}</td>
                            <td>{holding.marketValue}</td>
                            <td>{holding.portfolioPercent}</td>
                            <td>{holding.totalgainloss}</td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    );
}

export default Portfolio;
