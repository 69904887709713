import React from 'react';
import '../App.css'; 

function Home() {
  return (
    
    <div className="container full-height">      
      <main className="px-3">      
        <h1>RGNR Capital Partners</h1>
      </main>
      <footer className="mt-auto"></footer>
    </div>
    
  );
}

export default Home;

