import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  // Redirect logged in users
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="container mt-5">
      <h1>Login</h1>
      <p>Please log in to access the Dashboard.</p>
      <button className="btn btn-primary" onClick={() => loginWithRedirect()}>
        Log In
      </button>
    </div>
  );
}

export default Login;
