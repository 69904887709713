import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Dashboard from './components/Dashboard';
import Login from './components/Admin';
import Home from './components/Home';
import Letters from './components/Letters';
import Profile from './components/Profile';
import Portfolio from './components/Portfolio';

function PrivateRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return isAuthenticated ? children : <Navigate to="/admin" replace />;
}

function App() {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = `${window.location.origin}/dashboard`; // Ensure this matches the URL expected by Auth0

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      scope="openid profile email"
    >
      <Router>
        <Routes>
          <Route path="/admin" element={<Login />} />          
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/letters" element={<PrivateRoute><Letters /></PrivateRoute>} />  
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/portfolio" element={<PrivateRoute><Portfolio /></PrivateRoute>} />      
        </Routes>
      </Router>
    </Auth0Provider>
  );
}

export default App;
